<template>
  <v-row>
    <v-col cols="12">
      <span>จำนวนนักศึกษา</span>
    </v-col>
    <v-col cols="12" md="6">
      <span>นักศึกษาที่สำเร็จการศึกษา</span>
      <v-text-field
        hide-details
        v-model="form.graduated_amount"
        outlined
        dense
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <span>นักศึกษาปริญญาโท: </span>
      <v-text-field
        hide-details
        v-model="form.master_amount"
        outlined
        dense
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <span>นักศึกษาปริญญาเอก: </span>
      <v-text-field
        hide-details
        v-model="form.doctor_amount"
        outlined
        dense
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="6"></v-col>
    <v-col cols="12">
      <!-- <v-btn @click="back()">cancel</v-btn> -->
      <v-btn style="float: right" color="#2AB3A3" @click="submit()">save</v-btn>
    </v-col>
  </v-row>
</template>


<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      form: {
        graduated_amount: "",
        master_amount: "",
        doctor_amount: "",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/c_configs/2`
      );
      console.log("response", response);
      this.form = response.data.data;
    },
    async submit() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/c_configs/2`,
        this.form,
        auth
      );
      console.log("response", response);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขข้อมูลสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getData();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>